@charset "utf-8";

#service {
  background-color: white;
  padding-top: 7.5rem;
  padding-bottom: 12.5rem;
  scroll-margin-top: 10rem;
}

.top_service {
  margin-bottom: 15rem;
}

.service_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.service_title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #203864;
  margin-bottom: 7rem;
}

.bottom_service .service_title {
  margin-bottom: 6rem;
}

.service_title em {
  color: var(--green-color);
}

.service_items_wrap {
  display: grid;
}

.top_service .service_items_wrap {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 5rem;
}

.bottom_service .service_items_wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mobile_service_br {
  display: none;
}

.mobile_service_divide {
  display: none;
}

@media (max-width: 720px) {
  #service {
    padding-bottom: 7.5rem;
  }

  .top_service {
    margin-bottom: 12rem;
  }

  .top_service .service_items_wrap {
    column-gap: 3.6rem;
  }
}

@media (max-width: 550px) {
  #service {
    padding-top: 5rem;
    padding-bottom: 0;
  }

  .service_title {
    font-size: 1.8rem;
    margin-bottom: 6rem;
  }

  .mobile_service_br {
    display: block;
  }

  .service_items_wrap {
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    width: 100%;
  }

  .top_service {
    margin-bottom: 0;
  }

  .mobile_service_divide {
    display: block;
    height: 1rem;
    background-color: #e6ecef;
    margin: 4rem 0;
  }

  .mobile_service_divide:last-child {
    margin: 0;
  }

  .bottom_service.service_wrap {
    align-items: start;
  }

  .bottom_service .service_title {
    text-align: left;
    padding-left: 2rem;
  }
}
