@charset "utf-8";

#title {
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
}

.title_line {
  height: 0.2rem;
  width: 100%;
  background-color: black;
  opacity: 20%;
}

.title_text {
  font-size: 3rem;
  font-weight: bold;
  color: var(--blue-color);
  margin: 0 2.5rem;
}

@media (max-width: 550px) {
  #title {
    margin-bottom: 2.2rem;
  }

  .title_line {
    display: none;
  }

  .title_text {
    font-size: 2.4rem;
    margin-left: 2rem;
  }
}
