@charset "utf-8";

.top_service_img {
  margin-bottom: 1.5rem;
}

.top_service_title {
  font-size: 1.8rem;
  color: var(--blue-color);
  text-align: center;
  line-height: 1.3;
  word-break: keep-all;
}

.mobile_num {
  display: none;
}

@media (max-width: 720px) {
  .top_service_img {
    max-width: 11rem;
  }

  .top_service_title {
    font-size: 1.7rem;
  }
}

@media (max-width: 550px) {
  .top_service_img {
    display: none;
  }

  .top_service_mobile_br {
    display: none;
  }

  #top_service {
    display: flex;
    align-items: center;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    border-radius: 5rem;
    padding: 1.2rem 0;
    padding-left: 2.4rem;
    margin-bottom: 1.1rem;
  }

  .mobile_num {
    display: block;
    font-size: 2rem;
    font-weight: bold;
    color: var(--green-color);
    margin-right: 2rem;
  }

  .top_service_title {
    font-size: 1.6rem;
  }
}
