@charset "utf-8";

#inquire {
  margin-top: 15rem;
  margin-bottom: 11.5rem;
  overflow: hidden;
}

.inquire_header {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--blue-color);
  text-align: center;
  margin-bottom: 4.5rem;
}

.inquire_header em {
  color: var(--green-color);
}

.inquire_form_wrap {
  background-color: white;
  padding: 4rem;
  width: 90%;
  margin: 0 auto;
}

.inquire_form_wrap label {
  display: block;
}

.inquire_form_wrap label.inquire_checkbox {
  display: inline;
  width: auto;
}

.inquire_submit_button {
  display: block;
}

.inquire_title_label {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.inquire_title_label em {
  color: var(--green-color);
  margin-left: 0.8rem;
}

.inquire_form_wrap input {
  padding: 1.2rem 1.7rem;
  font-size: 1.5rem;
  width: 100%;
  background-color: #f6f7f8;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  margin-bottom: 3rem;
  outline: none;
}

.inquire_form_wrap input[type="checkbox"] {
  display: none;
}

.inquire_form_wrap input[type="checkbox"] + label {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  margin-right: 0.8rem;
}

.inquire_form_wrap input[type="checkbox"]:checked + label {
  border: none;
  background-image: url(../img/checked.svg);
  background-size: cover;
}

.inquire_divider {
  height: 0.2rem;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  margin-bottom: 3rem;
}

.inquire_agreement {
  display: flex;
}

.inquire_agreement .inquire_title_label {
  margin-bottom: 0;
  line-height: 1;
}

.inquire_submit_button {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  background-color: var(--green-color);
  padding: 1.2rem 8.8rem;
  margin: 0 auto;
  margin-top: 3rem;
}

.inquire_textarea {
  height: 19rem;
  padding: 1.2rem 1.7rem;
  font-size: 1.5rem;
  width: 100%;
  background-color: #f6f7f8;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  margin-bottom: 3rem;
  resize: none;
  outline: none;
}

@media (max-width: 720px) {
  #inquire.container {
    width: 100%;
    margin-bottom: 0;
  }

  .inquire_form_wrap {
    width: 100%;
    padding-bottom: 10rem;
  }
}

@media (max-width: 550px) {
  #inquire {
    margin-top: 3rem;
  }

  .inquire_header {
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }

  .inquire_title_label em {
    margin-left: 0.5rem;
  }

  .inquire_form_wrap {
    width: 100%;
    padding: 2rem;
  }

  .inquire_title_label {
    margin-bottom: 1rem;
  }

  .inquire_submit_button {
    width: 100%;
    padding: 1.5rem 0;
    border-radius: 0.5rem;
    margin-top: 2rem;
  }

  .mobile_inquire_divider {
    width: 100vw;
    height: 1px;
    transform: translateX(-5%);
  }
}
