@charset "utf-8";

footer {
  padding-top: 2.2rem;
  padding-bottom: 3rem;
  background: #272b3a;
}

.footer_logo {
  height: 2.3rem;
  width: 10rem;
  margin-bottom: 1.6rem;
}

.footer_text {
  font-size: 1.2rem;
  color: white;
  opacity: 50%;
  line-height: 1.5;
}

.mobile_footer_br {
  display: none;
}

@media (max-width: 720px) {
  .footer_text {
    line-height: 1.8;
  }
}

@media (max-width: 550px) {
  footer {
    padding: 2rem;
  }

  .footer_text {
    line-height: 1.7;
  }

  .mobile_footer_br {
    display: block;
  }
}
