@charset "utf-8";

#works {
  padding-top: 9.5rem;
  scroll-margin-top: 10rem;
}

.works_img_wrap {
  width: 95%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 3.4rem;
  column-gap: 4rem;
  margin: 0 auto;
  margin-bottom: 4.6rem;
}

.works_text {
  font-size: 1.8rem;
  color: #333;
  text-align: center;
}

.mobile_works_text {
  display: none;
}

.mobile_works_divide {
  display: none;
}

@media (max-width: 720px) {
  #works {
    padding-top: 7.5rem;
  }

  .works_img_wrap {
    row-gap: 3rem;
    column-gap: 3rem;
  }
}

@media (max-width: 550px) {
  #works {
    padding-top: 4rem;
    background: white;
  }

  .works_img_wrap {
    width: 100%;
    padding: 0 2rem;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 2rem;
  }

  .works_text {
    font-size: 1.6rem;
    padding-bottom: 3.6rem;
    margin-bottom: 0;
  }

  .mobile_works_text {
    display: block;
  }

  .mobile_works_divide {
    display: block;
    height: 1rem;
    background-color: #e6ecef;
  }
}
