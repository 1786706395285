@charset "utf-8";

#banner {
  background: url(../img/banner_bg_img.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 48.2rem;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10.5rem;
}

.banner_title {
  font-size: 3rem;
  color: white;
  font-weight: bold;
}

.banner_subtitle {
  font-size: 1.4rem;
  color: white;
  margin-top: 1.8rem;
  margin-bottom: 3.5rem;
}

.banner_buttons {
  display: flex;
}

.faq_button {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  background-color: var(--green-color);
  line-height: 1;
  height: 4rem;
  padding: 0 6rem;
  margin-right: 0.7rem;
}

.pdf_button {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  background-color: white;
  line-height: 1;
  height: 4rem;
  padding: 0 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf_button_icon {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1.1rem;
}

@media (max-width: 720px) {
  #banner {
    background: url(../img/mobile_banner_bg.png);
    background-size: cover;
  }

  .banner_title {
    text-align: center;
  }

  .banner_subtitle {
    text-align: center;
  }

  .banner_buttons {
    justify-content: center;
  }
}

@media (max-width: 550px) {
  #banner {
    padding-bottom: 0;
    height: 25.8rem;
    align-items: center;
  }

  .banner_buttons {
    display: none;
  }

  .banner_title {
    font-size: 1.8rem;
    text-align: center;
  }

  .banner_subtitle {
    width: 90%;
    font-size: 1.4rem;
    opacity: 70%;
    word-break: keep-all;
    margin: 2.3rem auto;
    margin-bottom: 0;
    text-align: center;
  }
}
