@charset "utf-8";

#contact {
  padding: 12rem 0;
  scroll-margin-top: 10rem;
}

.contact_map {
  width: 95%;
  height: 27rem;
  background: url("../img/map_1920.png");
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  margin-bottom: 4rem;
}

.contact_logo {
  height: 4rem;
  width: 16rem;
  margin: 0 auto;
  margin-bottom: 2.5rem;
}

.contact_text {
  font-size: 1.5rem;
  color: var(--black-color);
  text-align: center;
}

.contact_text em {
  font-weight: bold;
  color: #1661e9;
}

@media (max-width: 720px) {
  #contact {
    padding: 7.5rem 0;
  }

  #contact .container {
    width: 100%;
  }

  #contact #title {
    width: 90%;
    margin: 0 auto 5rem auto;
  }

  .contact_map {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 33rem;
  }
}

@media (max-width: 550px) {
  #contact {
    background-color: white;
    padding-top: 4rem;
    padding-bottom: 0;
  }

  #contact #title {
    margin-left: 0;
  }

  .contact_map {
    background-size: cover;
    background-position: center;
    height: 27rem;
  }

  .contact_logo {
    display: none;
  }

  .mobile_contact_br {
    display: none;
  }

  .contact_text div:first-child {
    width: 60%;
    word-break: keep-all;
    margin: 0 auto;
  }

  .contact_text {
    padding-bottom: 5rem;
  }
}
