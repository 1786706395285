@charset "utf-8";

.bottom_service_img {
  max-width: 22.8rem;
  margin: 0 auto;
  margin-bottom: 3.2rem;
  object-fit: cover;
}

.bottom_service_title {
  font-size: 3rem;
  font-weight: bold;
  color: var(--blue-color);
  line-height: 1.1;
  margin-bottom: 1.4rem;
}

.bottom_service_title em {
  color: #1e468f;
}

.bottom_service_list {
  display: flex;
  flex-direction: column;
}

.bottom_service_list li {
  font-size: 1.8rem;
  color: var(--blue-color);
  line-height: 1.7;
}

.bottom_service_list li::before {
  content: "-";
  margin-right: 0.4rem;
  color: var(--blue-color);
}

@media (max-width: 720px) {
  .bottom_service_img {
    max-width: 16.4rem;
  }

  .bottom_service_title {
    font-size: 2.4rem;
  }

  .bottom_service_list li {
    font-size: 1.5rem;
    line-height: 2;
  }
}

@media (max-width: 550px) {
  #bottom_service {
    border-top: 0.2rem solid rgba(0, 0, 0, 0.2);
    padding-top: 2.8rem;
    padding-bottom: 4.6rem;
  }

  .bottom_service_img {
    display: none;
  }

  .bottom_service_title {
    font-size: 2.4rem;
  }

  .bottom_service_list li {
    font-size: 1.6rem;
  }
}
