@charset "utf-8";

:root {
  --green-color: #0e6736;
  --blue-color: #203864;
  --black-color: #333;
}

/* 폰트 크기 초기화 */
html {
  font-size: 62.5%;
}
@media (max-width: 660px) {
  html {
    font-size: 55%;
  }
}
@media (max-width: 550px) {
  html {
    font-size: 62.5%;
  }
}

/* border-box 초기화 */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* 가로모드 글씨 확대 초기화 */
html {
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

/* 여백 초기화 */
body,
div,
ul,
li,
dl,
dt,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
input,
fieldset,
legend,
p,
select,
table,
th,
td,
tr,
textarea,
button,
form {
  margin: 0;
  padding: 0;
}

/* a 링크 초기화 */
a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}

/* 배경색 초기화 */
body {
  background-color: #f5f7fb;
}

body,
input,
textarea,
select,
button,
table {
  font-family: "LINESeed", serif;
  font-size: 1.4rem;
  font-weight: normal;
}

/* 폰트 스타일 초기화 */
em,
address {
  font-style: normal;
}

/* 블릿기호 초기화 */
dl,
ul,
li,
ol,
menu {
  list-style: none;
}

button {
  background: transparent;
}

/* 제목 테그 초기화 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1.4rem;
  color: var(--black);
  font-weight: normal;
}

/* 테두리 초기화 */
img,
fieldset {
  border: 0 none;
}

/* img 초기화 */
img {
  display: block;
  content: "";
}

/* 버튼 초기화 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  border: 0;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 피규어 초기화 */
figure {
  margin: 0;
}

/* p 줄바꿈 초기화 */
p {
  word-break: keep-all;
  line-height: 1.5;
}

/* 반응형 이미지 */
img,
video,
object {
  width: 100%;
  height: auto;
}

/* IR 효과 */
.ir_pm {
  display: block;
  overflow: hidden;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
}
.ir_wa {
  display: block;
  overflow: hidden;
  position: relative;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ir_su {
  overflow: hidden;
  position: absolute;
  width: 0;
  height: 0;
  line-height: 0;
  text-indent: -9999px;
}

/* 컨테이너 */
.container {
  width: 82rem;
  margin: 0 auto;
}

@media (max-width: 960px) {
  .container {
    width: 90%;
  }
}
@media (max-width: 550px) {
  .container {
    width: 100%;
  }
}

.displayNone {
  display: none !important;
}
