@charset "utf-8";

nav {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  padding: 3rem 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.nav_logo {
  cursor: pointer;
}

nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.web_logo {
  max-width: 14.5rem;
  height: auto;
}

.mobile_logo {
  display: none;
}

.nav_items {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
}

.nav_item {
  margin-right: 5rem;
  cursor: pointer;
  display: inline;
}

.web_button {
  margin-right: 0;
}

.nav_item button {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  background: var(--green-color);
  height: 4rem;
  padding: 0 3.8rem;
  line-height: 1;
}

@media (max-width: 720px) {
  .nav_item {
    margin-right: 3.5rem;
  }

  .web_button {
    margin-right: 0;
  }

  .nav_item button {
    padding: 1.2rem 2.1rem;
  }
}

@media (max-width: 550px) {
  nav {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0);

    background-color: white;
    padding: 2.5rem;
  }

  nav .container {
    flex-direction: column;
    align-items: start;
  }

  .web_logo {
    display: none;
  }

  .mobile_logo {
    display: block;
    height: 1.8rem;
    width: 8rem;
    margin-left: -0.5rem;
  }

  .web_button {
    display: none;
  }

  .nav_items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.3rem;
    margin-top: 2.8rem;
  }

  .nav_item {
    color: var(--black-color);
    margin-right: 0;
  }
}
