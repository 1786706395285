@charset "utf-8";

@font-face {
    font-family: 'LINESeed';
    src: url('../fonts/LINESeedKR-Bd.ttf') format('truetype');
    font-weight: 700;
}
@font-face {
    font-family: 'LINESeed';
    src: url('../fonts/LINESeedKR-Rg.ttf') format('truetype');
    font-weight: 400;
}