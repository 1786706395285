@charset "utf-8";

#works_info {
  margin-top: 11rem;
  margin-bottom: 11.7rem;
}

.works_tab_wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 6rem;
  scroll-margin-top: 10rem;
}

.works_tab_divider {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-top: 6rem;
}

.works_tab_divider.line7 {
  display: none;
}

@media (max-width: 550px) {
  #works_info {
    margin-top: 0;
    margin-bottom: 5rem;
  }

  .works_tab_wrapper {
    padding-top: 3.5rem;
  }

  .works_tab_divider {
    height: 1.1rem;
    background: #e6ecef;
    margin-top: 3.5rem;
  }
}
