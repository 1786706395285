@charset "utf-8";

#works_tab {
  display: grid;
  grid-template-columns: 21rem 1fr;
  column-gap: 7.5rem;
  row-gap: 2.7rem;
}

.tab_title {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.3;
  color: #272b3a;
  margin-bottom: 1.7rem;
  word-break: keep-all;
}

.tab_title em {
  color: #1e468f;
}

.tab_tag_wrapper {
  display: flex;
  flex-wrap: wrap;
}

.tab_tag {
  font-size: 9px;
  font-weight: bold;
  color: white;
  background: var(--blue-color);
  padding: 0.5rem 1.1rem;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
}

.tab_desc_title {
  font-size: 15px;
  font-weight: bold;
  color: #1e468f;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.tab_desc_title:first-child {
  margin-top: 0;
}

.rect_tab_wrapper {
  max-width: 20rem;
  width: 100%;
}

.mobile_rect_img {
  display: none;
}

.mobile_tab_divider {
  display: none;
}

.tab_desc {
  font-size: 1.5rem;
  line-height: 1.5;
  padding-left: 1rem;
}

.mobile_tab_list {
  display: flex;
}

.works_tab_dot::before {
  content: "";
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  background-color: black;
  margin-left: 1rem;
  margin-bottom: 0.4rem;
  border-radius: 5rem;
}

@media (max-width: 550px) {
  #works_tab {
    display: block;
    padding: 0 2rem;
  }

  .rect_tab_wrapper {
    display: none;
  }

  .mobile_rect_img {
    display: block;
    margin-bottom: 2rem;
  }

  .tab_title {
    font-size: 2.2rem;
  }

  .tab_tag {
    font-size: 1.2rem;
  }

  .mobile_tab_divider {
    display: block;
    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 2.5rem 0;
  }

  .tab_desc_title {
    font-size: 1.6rem;
  }

  .tab_desc {
    font-size: 1.6rem;
    margin-bottom: 0.7rem;
    line-height: 1.3;
    word-break: keep-all;
  }

  .tab_desc::before {
    display: none;
  }

  .img_tab_wrapper {
    margin-top: 2.2rem;
  }
}
