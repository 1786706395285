@charset "utf-8";

#mobile_button {
  display: none;
}

@media (max-width: 550px) {
  #mobile_button {
    position: fixed;
    bottom: 0;
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2.2rem 2rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  #mobile_button button {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 1.3rem 0;
    border-radius: 0.5rem;
  }

  .top_button {
    width: 100%;
    background: var(--green-color);
    color: white;
    margin-bottom: 1.3rem;
  }

  .bottom_button {
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
  }

  .bottom_button img {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1rem;
  }
}
